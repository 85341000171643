














































































































































import { Component, Vue, Inject, Watch } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import { ProjectDetailViewModel } from '../viewmodels/project-detail-viewmodel'
import ProjectCardStatus from '@/components/project-card/project-card-status.vue'

@Observer
@Component({
  components: {
    ProjectCardStatus,
  },
})
export default class SummaryInfomationDesktop extends Vue {
  @Inject() vm!: ProjectDetailViewModel

  currentTab = 'description'

  toggleTab(tabName: string): void {
    this.currentTab = tabName
    this.$emit('onTabClick', tabName)
  }

  applyPool() {
    this.$router.push(`${this.vm.poolStore?.pool?.unicodeName}/whitelist`)
  }
}
