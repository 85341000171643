






















import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import ProjectCardCountDown from './project-card-countdown.vue'

@Observer
@Component({
  components: {
    ProjectCardCountDown,
  },
})
export default class ProjectCardStatus extends Vue {
  @Prop({}) status!: string
  @Prop({}) targetTime!: number
  @Prop({}) statusColor!: string
}
